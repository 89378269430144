<template>
  <div>
    <div class="play_box" :style="{ width: all.clientWidths + 'px', height: all.clientHeights + 'px' }"></div>
    <audio :src="all.url" id="audio" @canplay="getDuration" @timeupdate="updateTime"></audio>
  </div>
</template>
  
<script>

export default {
  name: '',
  inject: ['reload'],
  props: {
    datalist: {
      type: Object,
      default: () => {
        return {
          url: "",
          color: "",
          clientWidths: "",
          clientHeights: "",
        }
      }
    },
  },
  data() {
    return {
      url: "",
      color: "green",
      clientWidths: 200,
      clientHeights: 100,
      audio: '',
      fftSize: 256,
      gap: 1,
      duration: '',
      currentTime: '',
      isType:false,
    }
  },
  computed: {
    all() {
      let Obj = this.datalist
      if (!Obj.url) {
        Obj.url = this.url
      }
      if (!Obj.color) {
        Obj.color = this.color
      }
      if (!Obj.clientWidths) {
        Obj.clientWidths = this.clientWidths
      }
      if (!Obj.clientHeights) {
        Obj.clientHeights = this.clientHeights
      }

      return Obj
    }
  },
  components: {

  },
  created() {
 
  },

 async mounted() {
    
   await this.divs()
  },

  methods: {
    divs(){
      this.audio = document.getElementById('audio')
    },

    // 点击播放
    play() {
      var that = this
      return new Promise((resolve, reject) => {
        if (that.audio.canPlayType('audio/mpeg') != '') {
          // console.log('支持MP3格式');
          resolve()
        } else {
          reject()
        }
        that.isType =true
       
        // console.log(this.all.color,"11111",this.all.url);

        draw(this.all.url, this.all.color)
        function draw(url, pink) {
          const { clientWidth: width, clientHeight: height } = document.getElementsByClassName('play_box')[0]
          that.audio.src = url
          that.audio.controls = false
          // that.audio.load()
          const canvas = document.createElement('canvas')
          const play_box = document.getElementsByClassName('play_box')[0]
          canvas.width = play_box.offsetWidth
          canvas.height = play_box.offsetHeight
          document.body.appendChild(that.audio)
          play_box.appendChild(canvas)
          const canvasCtx = canvas.getContext('2d')
          const audioCtx = new AudioContext
          const sourceNode = audioCtx.createMediaElementSource(that.audio)
          // 音频分析器
          const analyser = audioCtx.createAnalyser()
          analyser.fftSize = that.fftSize
          sourceNode.connect(analyser)
          analyser.connect(audioCtx.destination)
          const len = analyser.frequencyBinCount
          const buffer = new Uint8Array(len)
          const gap = that.gap // 间隙
          const pillarWidth = (width / len) - gap
          const gradient = canvasCtx.createLinearGradient(0, 0, 0, 500)
          gradient.addColorStop(.6, '#fb7299')
          gradient.addColorStop(.1, pink)
          gradient.addColorStop(.7, 'pink')

         


          // 波动音符 动画
          const render = () => {
            
            canvasCtx.fillStyle = '#fff'
            canvasCtx.fillRect(0, 0, width, height)
            analyser.getByteFrequencyData(buffer)
            let x = 0
            buffer.forEach(v => {
              const pillarHeight = (v / 255) * height
              canvasCtx.fillStyle = gradient
              canvasCtx.fillRect(x, height - (pillarHeight || 2), pillarWidth, pillarHeight || 2)
              x += (pillarWidth + gap)
            })
            requestAnimationFrame(render)
          }
          render()
          that.audio.crossOrigin = 'anonymous';
          that.audio.autoplay = true;
          that.audio.play()
          
        }
      })
    },
    loads(){
      this.audio.load()
    },
    getDuration() {
      var that = this
      new Promise((resolve, reject) => {
        resolve(that.audio.duration)
        that.$emit("currentTime", that.audio.duration)
        // console.log((that.audio.duration), "一共的");
      })
    },
    updateTime(e) {
      var that = this
      new Promise((resolve, reject) => {
        resolve(e)
        that.currentTime = parseInt(e.target.currentTime)
        var rangeSlideVal = parseInt((that.currentTime / that.audio.duration) * 100)
        that.$emit("duration", rangeSlideVal,e.target.currentTime)
        if (e.target.currentTime == that.audio.duration) {
          that.audio.pause(); //暂停 播放
          that.$emit("stop", false)
        }
      })
    },

    stops() {
      var that = this
      return new Promise((resolve, reject) => {
        that.audio.pause(); //暂停
        resolve()
        this.isType =false
      })
    },
  },
}
</script>
  
<style scoped>


</style>
  